import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useSelector } from "react-redux";
import logo from "../../assests/logo-khalihan.png";
const { REACT_APP_BACKOFFICE_URL } = process.env;

function Header() {
	const activeMenuReducer = useSelector((state) => state.rootReducer.activeMenu);
	var activeMenu = activeMenuReducer.ActiveMenu;
	useEffect(() => {
		$(".nav-item").click(function () {
			$(".nav-item").removeClass("active");
			$(this).addClass("active");
		});
	}, []);
	return (
		<>
			<div className="container-fluid px-5 d-none d-lg-block">
				<div className="row gx-5  align-items-center">
					<div className="col-lg-3">
						<div className="d-flex align-items-center justify-content-start">
						<a href="index.html" className="navbar-brand ms-lg-5">
								<img src={logo} alt="logo" className="logoImage" />
							</a>
						</div>
					</div>

					<div className="col-lg-6">
						
					</div>
					<div className="col-lg-3">
						<div className="d-flex align-items-center justify-content-end">
							<a className="btn btn-primary btn-square rounded-circle me-2" href="#"><i className="fab fa-twitter"></i></a>
							<a className="btn btn-primary btn-square rounded-circle me-2" href="#"><i className="fab fa-facebook-f"></i></a>
							<a className="btn btn-primary btn-square rounded-circle me-2" href="https://www.linkedin.com/in/खलिहान-खेत-खलिहान-से-आप-तक-52b83b344" target="_blank"><i className="fab fa-linkedin-in"></i></a>
							<a className="btn btn-primary btn-square rounded-circle" href="#"><i className="fab fa-instagram"></i></a>
						</div>
					</div>
				</div>
			</div>

			<nav className="navbar navbar-expand-lg bg-primary navbar-dark shadow-sm py-3 py-lg-0 px-3 px-lg-5">
				<a href="index.html" className="navbar-brand d-flex d-lg-none">
					<h1 className="m-0 display-4 text-secondary"><span className="text-white">Khalihan</span>Portal</h1>
				</a>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarCollapse">
					<div className='navbar-nav mx-auto py-0' >
						<a href="/" className={`nav-item nav-link ${activeMenu === "HomePage" ? "active" : ""}`}>Home</a>
						<Link to='/about' className="nav-item nav-link">About</Link>
						<Link to='/plat-form' className="nav-item nav-link">PlatForm</Link>
						<a href="" className="nav-item nav-link">Verticals</a>
						<div className="nav-item dropdown">
							<a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Resources</a>
							<div className="dropdown-menu m-0">
								<Link to="/blog-grid" className="dropdown-item">Blog Grid</Link>
								<Link to="/blog-detail" className="dropdown-item">Blog Detail</Link>

								<Link to='/features' className="dropdown-item">Features</Link>
								<Link to="/team" className="dropdown-item">The Team</Link>
								<Link to="/testimonials" className="dropdown-item">Testimonial</Link>


							</div>
						</div>
						<Link to='/contact' className="nav-item nav-link">Contact</Link>
					</div>
					<div className='navbar-nav mx-auto py-0' >
						<Link to='/partners' className={`nav-item nav-link ${activeMenu === "Partners" ? "active" : ""}`}>Partners</Link>
						<Link to='/testimonials' id="testimonials_Id" className={`nav-item nav-link ${activeMenu === "Testimonials" ? "active" : ""}`}>Testimonials</Link>
					</div>
					<div className='navbar-nav mx-auto py-0' >
						<Link to={REACT_APP_BACKOFFICE_URL + "/login"} target="blank" className="nav-item nav-link login-button">Login</Link>
					</div>
				</div>
			</nav>
		</>
	);
}

export default Header;
