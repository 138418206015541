import React, { useEffect, useState } from "react";
import { activeMenuAction } from "../../actions/index";
import { useDispatch } from "react-redux";
import blogImg from "../../../src/assests/img/blog-1.jpg" ;
import featuareImg from "../../../src/assests/img/feature.png" ;
import team from "../../../src/assests/img/team-1.jpg" ;
import team2 from "../../../src/assests/img/team-2.jpg" ;
import team3 from "../../../src/assests/img/team-3.jpg" ;
import testitmonialImg from "../../../src/assests/img/testimonial-1.jpg" ;
import testitmonialImg2 from "../../../src/assests/img/testimonial-2.jpg" ;

const Features = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
      dispatch(activeMenuAction('Features'));
  },[])

  return (
    <>
  <div className="container-fluid bg-primary facts py-5 mb-5">
        <div className="container py-5">
            <div className="row gx-5 gy-4">
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-star fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Our Experience</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-users fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Farm Specialist</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-check fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Complete Project</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="d-flex">
                        <div className="bg-secondary rounded-circle d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-mug-hot fs-4 text-white"></i>
                        </div>
                        <div className="ps-4">
                            <h5 className="text-white">Happy Clients</h5>
                            <h1 className="display-5 text-white mb-0" data-toggle="counter-up">12345</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid bg-primary feature py-5 pb-lg-0 my-5">
        <div className="container py-5 pb-lg-0">
            <div className="mx-auto text-center mb-3 pb-2 mx-500">
                <h6 className="text-uppercase text-secondary">Features</h6>
                <h1 className="display-5 text-white">Why Choose Us!!!</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-3">
                    <div className="text-white mb-5">
                        <div className="bg-secondary rounded-pill d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-seedling fs-4 text-white"></i>
                        </div>
                        <h4 className="text-white">100% Organic</h4>
                        <p className="mb-0">Labore justo vero ipsum sit clita erat lorem magna clita</p>
                    </div>
                    <div className="text-white">
                        <div className="bg-secondary rounded-pill d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-award fs-4 text-white"></i>
                        </div>
                        <h4 className="text-white">Award Winning</h4>
                        <p className="mb-0">Labore justo vero ipsum sit clita erat lorem magna clita</p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="d-block bg-white h-100 text-center p-5 pb-lg-0">
                        <p>At et justo elitr amet sea at. Magna et sit vero at ipsum sit et dolores rebum. Magna sea eos sit dolor, ipsum amet no tempor ipsum eirmod lorem eirmod diam tempor dolor eos diam et et diam dolor ea. Clita est rebum amet dolore sit. Dolor stet dolor duo clita, vero dolor ipsum amet dolore magna lorem erat stet sed vero dolor</p>
                        <img className="img-fluid" src={featuareImg} alt=""/>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="text-white mb-5">
                        <div className="bg-secondary rounded-pill d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-tractor fs-4 text-white"></i>
                        </div>
                        <h4 className="text-white">Modern Farming</h4>
                        <p className="mb-0">Labore justo vero ipsum sit clita erat lorem magna clita</p>
                    </div>
                    <div className="text-white">
                        <div className="bg-secondary rounded-pill d-flex align-items-center justify-content-center mb-3 height-with-60" >
                            <i className="fa fa-phone-alt fs-4 text-white"></i>
                        </div>
                        <h4 className="text-white">24/7 Support</h4>
                        <p className="mb-0">Labore justo vero ipsum sit clita erat lorem magna clita</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid py-5">
        <div className="container">
            <div className="mx-auto text-center mb-5  mx-500" >
                <h6 className="text-primary text-uppercase">Products</h6>
                <h1 className="display-5">Our Fresh & Organic Products</h1>
            </div>
            <div className="owl-carousel product-carousel px-5">
                <div className="pb-5">
                    <div className="product-item position-relative bg-white d-flex flex-column text-center">
                        <img className="img-fluid mb-4" src="img/product-1.png" alt=""/>
                        <h6 className="mb-3">Organic Vegetable</h6>
                        <h5 className="text-primary mb-0">$19.00</h5>
                        <div className="btn-action d-flex justify-content-center">
                            <a className="btn bg-primary py-2 px-3" href=""><i className="bi bi-cart text-white"></i></a>
                            <a className="btn bg-secondary py-2 px-3" href=""><i className="bi bi-eye text-white"></i></a>
                        </div>
                    </div>
                </div>
                <div className="pb-5">
                    <div className="product-item position-relative bg-white d-flex flex-column text-center">
                        <img className="img-fluid mb-4" src="img/product-2.png" alt=""/>
                        <h6 className="mb-3">Organic Vegetable</h6>
                        <h5 className="text-primary mb-0">$19.00</h5>
                        <div className="btn-action d-flex justify-content-center">
                            <a className="btn bg-primary py-2 px-3" href=""><i className="bi bi-cart text-white"></i></a>
                            <a className="btn bg-secondary py-2 px-3" href=""><i className="bi bi-eye text-white"></i></a>
                        </div>
                    </div>
                </div>
                <div className="pb-5">
                    <div className="product-item position-relative bg-white d-flex flex-column text-center">
                        <img className="img-fluid mb-4" src="img/product-1.png" alt=""/>
                        <h6 className="mb-3">Organic Vegetable</h6>
                        <h5 className="text-primary mb-0">$19.00</h5>
                        <div className="btn-action d-flex justify-content-center">
                            <a className="btn bg-primary py-2 px-3" href=""><i className="bi bi-cart text-white"></i></a>
                            <a className="btn bg-secondary py-2 px-3" href=""><i className="bi bi-eye text-white"></i></a>
                        </div>
                    </div>
                </div>
                <div className="pb-5">
                    <div className="product-item position-relative bg-white d-flex flex-column text-center">
                        <img className="img-fluid mb-4" src="img/product-2.png" alt=""/>
                        <h6 className="mb-3">Organic Vegetable</h6>
                        <h5 className="text-primary mb-0">$19.00</h5>
                        <div className="btn-action d-flex justify-content-center">
                            <a className="btn bg-primary py-2 px-3" href=""><i className="bi bi-cart text-white"></i></a>
                            <a className="btn bg-secondary py-2 px-3" href=""><i className="bi bi-eye text-white"></i></a>
                        </div>
                    </div>
                </div>
                <div className="pb-5">
                    <div className="product-item position-relative bg-white d-flex flex-column text-center">
                        <img className="img-fluid mb-4" src="img/product-1.png" alt=""/>
                        <h6 className="mb-3">Organic Vegetable</h6>
                        <h5 className="text-primary mb-0">$19.00</h5>
                        <div className="btn-action d-flex justify-content-center">
                            <a className="btn bg-primary py-2 px-3" href=""><i className="bi bi-cart text-white"></i></a>
                            <a className="btn bg-secondary py-2 px-3" href=""><i className="bi bi-eye text-white"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid bg-testimonial py-5 my-5">
        <div className="container py-5">
            <div className="row justify-content-center">
                <div className="col-lg-7">
                    <div className="owl-carousel testimonial-carousel p-5">
                        <div className="testimonial-item text-center text-white">
                            <img className="img-fluid mx-auto p-2 border border-5 border-secondary rounded-circle mb-4" src={testitmonialImg} alt=""/>
                            <p className="fs-5">Dolores sed duo clita justo dolor et stet lorem kasd dolore lorem ipsum. At lorem lorem magna ut et, nonumy labore diam erat. Erat dolor rebum sit ipsum.</p>
                            <hr className="mx-auto w-25"/>
                            <h4 className="text-white mb-0">Client Name</h4>
                        </div>
                        <div className="testimonial-item text-center text-white">
                            <img className="img-fluid mx-auto p-2 border border-5 border-secondary rounded-circle mb-4" src={testitmonialImg2 } alt=""/>
                            <p className="fs-5">Dolores sed duo clita justo dolor et stet lorem kasd dolore lorem ipsum. At lorem lorem magna ut et, nonumy labore diam erat. Erat dolor rebum sit ipsum.</p>
                            <hr className="mx-auto w-25"/>
                            <h4 className="text-white mb-0">Client Name</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid py-5">
        <div className="container">
            <div className="mx-auto text-center mb-5 mx-500">
                <h6 className="text-primary text-uppercase">The Team</h6>
                <h1 className="display-5">We Are Professional Organic Farmers</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4 col-md-6">
                    <div className="row g-0">
                        <div className="col-10">
                            <div className="position-relative">
                                <img className="img-fluid w-100" src={team} alt=""/>
                                <div className="position-absolute start-0 bottom-0 w-100 py-3 px-4" >
                                    <h4 className="text-white">Farmer Name</h4>
                                    <span className="text-white">Designation</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="h-100 d-flex flex-column align-items-center justify-content-around bg-secondary py-5">
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-twitter text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-facebook-f text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#" href="https://www.linkedin.com/in/खलिहान-खेत-खलिहान-से-आप-तक-52b83b344" target="_blank"><i className="fab fa-linkedin-in text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-instagram text-secondary"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="row g-0">
                        <div className="col-10">
                            <div className="position-relative">
                                <img className="img-fluid w-100" src={team2} alt=""/>
                                <div className="position-absolute start-0 bottom-0 w-100 py-3 px-4" >
                                    <h4 className="text-white">Farmer Name</h4>
                                    <span className="text-white">Designation</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="h-100 d-flex flex-column align-items-center justify-content-around bg-secondary py-5">
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-twitter text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-facebook-f text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-linkedin-in text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-instagram text-secondary"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="row g-0">
                        <div className="col-10">
                            <div className="position-relative">
                                <img className="img-fluid w-100" src={team3} alt=""/>
                                <div className="position-absolute start-0 bottom-0 w-100 py-3 px-4" >
                                    <h4 className="text-white">Farmer Name</h4>
                                    <span className="text-white">Designation</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="h-100 d-flex flex-column align-items-center justify-content-around bg-secondary py-5">
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-twitter text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-facebook-f text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-linkedin-in text-secondary"></i></a>
                                <a className="btn btn-square rounded-circle bg-white" href="#"><i className="fab fa-instagram text-secondary"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
    <div className="container-fluid py-5">
        <div className="container">
            <div className="mx-auto text-center mb-5 mx-500">
                <h6 className="text-primary text-uppercase">Our Blog</h6>
                <h1 className="display-5">Latest Articles From Our Blog Post</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={blogImg} alt=""/>
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">Lorem elitr magna stet eirmod labore amet</h4>
                            <span className="text-white fw-bold">Jan 01, 2050</span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={blogImg} alt=""/>
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">Lorem elitr magna stet eirmod labore amet</h4>
                            <span className="text-white fw-bold">Jan 01, 2050</span>
                        </a>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="blog-item position-relative overflow-hidden">
                        <img className="img-fluid" src={blogImg} alt=""/>
                        <a className="blog-overlay" href="">
                            <h4 className="text-white">Lorem elitr magna stet eirmod labore amet</h4>
                            <span className="text-white fw-bold">Jan 01, 2050</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
  );
}

export default Features;
